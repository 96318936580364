
import get from 'lodash/get'
import { mapActions, mapGetters } from 'vuex'

export default {
  data() {
    return {
      menus: [
        {
          key: 'myorders',
          text: 'myOrders',
          url: '/manage/my-orders',
          icon: 'ic-myorder.svg',
        },
        {
          key: 'wallet',
          text: 'wallet',
          url: '/manage/wallet',
          icon: 'ic-wallet.svg',
        },
        // {
        //   key: 'notification',
        //   text: 'Notification',
        //   url: '/notification',
        //   icon: 'ic-notification.svg',
        // },
        {
          key: 'addnewservice',
          text: 'addNewServices',
          url: '/manage/add-new-service',
          icon: 'ic-addnewservice.svg',
        },
        {
          key: 'myservices',
          text: 'myServices',
          url: '/manage/my-listings',
          icon: 'ic-myservice.svg',
        },
        {
          key: 'settings',
          text: 'settings',
          url: '/manage/settings',
          icon: 'ic-settings.svg',
        },
        // {
        //   key: 'faq',
        //   text: 'FAQ',
        //   url: '/p/faq',
        //   icon: 'ic-faq.svg',
        // },
        {
          key: 'feedback',
          text: 'feedback',
          url: '/manage/feedback',
          icon: 'ic-feedback.svg',
        },
        // {
        //   key: 'privacypolicy',
        //   text: 'Privacy Policy',
        //   url: '/p/privacy-policy',
        //   icon: 'ic-privacypolicy.svg',
        // },
        {
          key: 'logout',
          text: 'logout',
          url: '/logout',
          icon: 'ic-logout.svg',
        },
      ],
      selectedMenuKey: '',
      hoveredMenuKey: '',
      hover: false,
    }
  },
  computed: {
    ...mapGetters({
      creditBalance: 'wallet/getCreditBalance',
    }),
    currentPath() {
      return this.$route.path.split('/').filter(p => !!p)[0]
    },
    currentUserId() {
      return get(this.$auth, 'user.id')
    },
  },
  watch: {
    currentPath(value) {
      this.setKeyByPath(value)
    },
  },
  mounted() {
    this.setKeyByPath(this.currentPath)
    if (this.currentUserId) {
      this.getWallets(this.currentUserId)
    }
  },
  methods: {
    setKeyByPath(path) {
      const menuItem = this.menus.find(item => item.key === path)
      this.selectedMenuKey = menuItem ? menuItem.key : 'myorder'
    },
    getImageUrl(item) {
      return require(`~/assets/svg/${item.icon}`)
    },
    setSelectedMenuKey(key) {
      this.selectedMenuKey = key
    },
    mouseOver(key) {
      this.hover = true
      this.hoveredMenuKey = key
    },
    mouseLeave() {
      this.hover = false
      this.hoveredMenuKey = ''
    },
    ...mapActions({
      getWallets: 'wallet/getUserWallet',
    }),
  },
}
