import { RefreshScheme } from '~auth/runtime'

export default class CustomRefreshScheme extends RefreshScheme {
  refreshTokens() {
    // Refresh endpoint is disabled
    if (!this.options.endpoints.refresh) {
      return Promise.resolve()
    }

    // Token and refresh token are required but not available
    if (!this.check().valid) {
      return Promise.resolve()
    }

    // Get refresh token status
    const refreshTokenStatus = this.refreshToken.status()

    // Refresh token is expired. There is no way to refresh. Force reset.
    if (refreshTokenStatus.expired()) {
      this.$auth.reset()

      throw new Error('Both token and refresh token have expired. Your request was aborted.')
    }

    // Delete current token from the request header before refreshing, if `tokenRequired` is disabled
    if (!this.options.refreshToken.tokenRequired) {
      this.requestHandler.clearHeader()
    }

    const endpoint = {
      data: {
        tokenExp: null,
      },
    }

    const headers = {
      ...this.options.endpoints.refresh,
      headers: {
        authorization: this.refreshToken.get(),
      },
    }

    // Make refresh request
    return this.$auth
      .request(endpoint, headers)
      .then(response => {
        // Update tokens
        this.updateTokens(response, { isRefreshing: true })
        return response
      })
      .catch(error => {
        this.$auth.callOnError(error, { method: 'refreshToken' })
        return Promise.reject(error)
      })
  }
}
