export default {
  setLoading(state, loading) {
    state.loading = loading
  },
  setCMS(state, data) {
    state.cms = data
  },
  setAllCMS(state, data) {
    state.allCMS = data
  },
}
