import stringify from 'qs/lib/stringify'
import { showError } from '~/utils'
import {
  ORDER_STATUS_ACCEPTED,
  ORDER_STATUS_COMPLETED,
  ORDER_STATUS_COMPLETED_PENDING,
  ORDER_STATUS_CREATED,
} from '../../utils/orderUtils'

const stringifyOption = {
  indices: false,
  skipNulls: true,
  arrayFormat: 'repeat',
  encode: false,
}

export default {
  // eslint-disable-next-line consistent-return
  async getUserOrders({ commit }, originalParams) {
    const { tab, ...params } = originalParams

    commit('setLoading', true)
    try {
      const {
        data: { data: orders },
      } = await this.$axios.get(`/orders/me?${stringify(params, stringifyOption)}`)

      if (tab === 'active') {
        const activeOrders = orders.filter(o => [ORDER_STATUS_CREATED, ORDER_STATUS_ACCEPTED, ORDER_STATUS_COMPLETED_PENDING].includes(
          o.status,
        ))
        commit('setActiveOrders', activeOrders)
        commit('setRefreshActiveOrders', false)
      }

      if (tab === 'completed') {
        const completedOrders = orders.filter(o => o.status === ORDER_STATUS_COMPLETED)
        commit('setCompletedOrders', completedOrders)
      }

      commit('setUserOrders', orders)
      return orders
    } catch (error) {
      showError(error, 'Get user orders failed!')
    } finally {
      commit('setLoading', false)
    }
  },
  // eslint-disable-next-line consistent-return
  async getUserById(id) {
    try {
      const {
        data: { data },
      } = await this.$axios.get(`/users/${id}/`)
      return data
    } catch (error) {
      showError(error, 'Get user failed!')
    }
  },
  // eslint-disable-next-line no-unused-vars
  async getOrders({ commit }, params) {
    const filter = {
      usecases_client: params.searchKeywords ? 'LISTING_TX' : 'LIST_LISTING_TX',
      perspective: params.asBuyer ? 'FROM_BUYER' : 'FROM_SELLER',
      statuses: params.status === 'ALL' ? undefined : params.status,
      search_keywords: params.searchKeywords,
      catalog_types: params.catalogTypes,
      page: params.page,
      per_page: params.per_page,
    }
    const cleanedFilter = Object.entries(filter).reduce((acc, [key, value]) => {
      if (value !== undefined) {
        acc[key] = value
      }
      return acc
    }, {})

    let orders

    const paramQuery = new URLSearchParams(cleanedFilter).toString()

    try {
      const { data } = await this.$apiV2.get(`/payment-order/orders?${paramQuery}`)
      orders = data
    } catch (error) {
      showError(error, 'Get orders failed!')
    }

    return orders
  },
  async getStatusCount(_, perspective = 'FROM_SELLER') {
    let count
    try {
      const { data } = await this.$apiV2.get(`/payment-order/orders/statuses-summary?perspective=${perspective}`)
      count = data.statuses_count
    } catch (error) {
      showError(error, 'Get orders failed!')
    }

    return count
  },
}
