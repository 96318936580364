export default {
  setUserWallet(state, data) {
    state.wallets = data
  },
  setUserTransactions(state, data) {
    state.transactions = data
  },
  setPackages(state, data) {
    state.packages = data
  },
  setNewBalance(state, data) {
    state.newBalance = data
  },
  setUserTransactionDetails(state, data) {
    state.transactionDetails = data
  },
  setConfirmationAccount(state, data) {
    state.confirmationAccount = data
  },
}
