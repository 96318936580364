const initialState = () => ({
  user: [],
  userGames: [],
  userGameSpecs: [],
  marketplaceGames: [],
  userProfile: {},
  userAchievements: [],
  userShowreels: [],
  userFollowing: [],
  userSubscriptionsObject: [],
  userSubscriptions: [],
  userSubscriptionsUserId: [],
  userGoal: null,
  paymentJustSucceed: false,
  userSummary: {},
  userTier: {},
  userCompletedGoals: [],
  userProgressionTask: {},
  highlightEnableDonation: false,
  highlightEnableMembership: false,
  highlightCreatePost: false,
  highlightShopCard: false,
  highlightStreamAlert: false,
  isJustEnabledDonations: false,
  userDiscountPPV: [],
  userDiscountShop: [],
  userDiscountMembership: [],
  fetchGoalLoading: false,
})

export default initialState
