export default {
  setCategories(state, data) {
    state.categories = data
  },
  setCategory(state, data) {
    state.category = data
  },
  setGames(state, data) {
    state.games = data
  },
  setServiceGames(state, data) {
    state.serviceGames = data
  },
  setRecommendations(state, data) {
    state.recommendations = data
  },
  setUserServices(state, data) {
    state.userServices = data
  },
  setUserCommissions(state, data) {
    state.userCommissions = data
  },
  setLoadingRecommendations(state, value) {
    state.loadingRecommendations = value
  },
  setInterests(state, data) {
    state.interests = data
  },
}
