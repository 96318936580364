export default () => ({
  categories: [],
  games: [],
  recommendations: [],
  userServices: [],
  userCommissions: [],
  loadingRecommendations: false,
  category: {},
  serviceGames: [],
  interests: [],
})
