export default {
  setCountries(state, data) {
    state.allCountries = data
  },
  setCurrentCountryCode(state, data) {
    state.currentCountryCode = data
  },
  setLoadingHighlightBanner(state, value) {
    state.loadingHighlightBanner = value
  },
  setMembershipSelected(state, value) {
    state.membershipSelected = value
  },
  setHighlightBanners(state, data) {
    state.highlightBanners = data
  },
  setLoadingFeaturedBanner(state, value) {
    state.loadingFeaturedBanner = value
  },
  setFeaturedBanners(state, data) {
    state.featuredBanners = data
  },
  setLoadingGamerSpotlight(state, value) {
    state.loadingGamerSpotlight = value
  },
  setGamerSpotlight(state, data) {
    state.gamerSpotlight = data
  },
  setLoadingMediaShowreel(state, value) {
    state.loadingMediaShowreel = value
  },
  setMediaShowreels(state, data) {
    state.mediaShowreels = data
  },
  setLanguages(state, data) {
    state.languages = data
  },
  setLoadingSections(state, value) {
    state.loadingSections = value
  },
  setSections(state, data) {
    state.sections = data
  },
  setCountryUser(state, data) {
    state.countryUser = data
  },
  setLocale(state, data) {
    state.locale = data
  },
  setTiers(state, data) {
    state.tiers = data
  },
  setClaimDiscord(state, data) {
    state.claimDiscord = data
  },
  setInterest(state, data) {
    state.interest = data
  },
  setExistingStreamUser(state, data) {
    state.existingStreamUser = data
  },
}
