export async function addCache(cacheName, dir, data) {
  try {
    const cache = await caches.open(cacheName)
    const cachedData = {
      data,
      timestamp: Date.now(),
    }
    await cache.put(dir, new Response(JSON.stringify(cachedData)))
    return true
  } catch (error) {
    return false
  }
}

export async function deleteAllCache() {
  try {
    const keys = await caches.keys()
    return await Promise.all(keys.map(key => caches.delete(key)))
  } catch (error) {
    return false
  }
}

// eslint-disable-next-line consistent-return
export async function getCache(cacheName, dir) {
  try {
    // set cache for 1 week
    const maxAgeInSeconds = 7 * 24 * 60 * 60
    const cacheService = await caches.open(cacheName)
    const findCache = await cacheService.match(dir)
    if (!findCache || !findCache.ok) {
      return false
    }

    if (findCache) {
      const cachedData = await findCache.json()
      if (cachedData.timestamp + maxAgeInSeconds * 1000 > Date.now()) {
        return cachedData.data
      }
      // Data has expired, remove it from cache
      deleteAllCache()
    }
  } catch (error) {
    return false
  }
}
