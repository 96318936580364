import dayjs from 'dayjs'

import 'dayjs/locale/en'
import 'dayjs/locale/id'
import 'dayjs/locale/ms'
import 'dayjs/locale/zh'
import 'dayjs/locale/th'
import relativeTime from 'dayjs/plugin/relativeTime'
import updateLocale from 'dayjs/plugin/updateLocale'
import localizedFormat from 'dayjs/plugin/localizedFormat'
import timezone from 'dayjs/plugin/timezone'

dayjs.extend(relativeTime)
dayjs.extend(updateLocale)
dayjs.extend(localizedFormat)
dayjs.extend(timezone)

dayjs.locale('en')

export default (context, inject) => {
  context.$dayjs = dayjs
  inject('dayjs', dayjs)
}
