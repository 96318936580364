import { mapState } from 'vuex'
import get from 'lodash/get'
import CryptoJS from 'crypto-js'

export default {
  data() {
    return {
    }
  },
  computed: {
    ...mapState({
      countryCode: state => state.settings.countryUser,
      locale: state => state.settings.locale,
    }),
  },
  methods: {
    getStripeKey() {
      switch (this.countryCode) {
        case 'MY':
          return this.$config.malaysiaStripePk
        case 'TH':
          return this.$config.stripePublishableKey
        default:
          return this.$config.stripePublishableKey
      }
    },
    async checkPayment() {
      const transaction = this.$cookies.get('transaction')
      const getTransactionID = get(this.$route, 'query.transactionID')

      if (transaction && getTransactionID) {
        const key = CryptoJS.enc.Utf8.parse(process.env.NUXT_ENV_CRYPTO_KEY)
        const iv = CryptoJS.enc.Utf8.parse(process.env.NUXT_ENV_CRYPTO_IV)
        const decrypt = CryptoJS.AES.decrypt(transaction, key, {
          iv,
          mode: CryptoJS.mode.CBC,
        })
        const secret = decrypt.toString(CryptoJS.enc.Utf8)

        if (getTransactionID === secret) {
          const resp = await this.$axios.post('wallets/transaction-check', {
            transactionID: secret,
          })
          if (get(resp, 'data.data.status') === 'succeeded') {
            this.$cookies.remove('transaction')
            return true
          }
        }
        this.$cookies.remove('transaction')
        throw new Error('Failed to verify payment')
      }
      return false
    },
  },
}
