export default {
  setUserOrders(state, data) {
    state.orders = data
  },
  setActiveOrders(state, data) {
    state.activeOrders = data
  },
  setCompletedOrders(state, data) {
    state.completedOrders = data
  },
  setLoading(state, loading) {
    state.loading = loading
  },
  setRefreshActiveOrders(state, value) {
    state.refreshActiveOrders = value
  },
}
