export default async function ({ app, redirect }) {
  // the following look directly for the cookie created by nuxtjs/auth
  // instead of using $auth.loggedIn
  const cookieToken = await app.$cookies.get('auth._token.local')

  if (!cookieToken) {
    // redirect to login page
    redirect('/login')
  }

  // let the user see the page
}
