import stringify from 'qs/lib/stringify'
import capitalize from 'lodash/capitalize'
import get from 'lodash/get'
import uniq from 'lodash/uniq'
import { showError } from '~/utils'
import { setTemp, getTemp } from '~/utils/tempStorage'

export default {
  async getCurrentCountryCode({ commit }) {
    try {
      const res = await this.$axios.get('/settings/country')
      this.currentCountryCode = res.data.data.country_code
      commit('setCurrentCountryCode', res.data.data.country_code)
    } catch (error) {
      showError(error, 'Failed to load current location!')
    }
  },
  async getCountries({ commit, state }) {
    // do not reload if the data is committed
    if (state.allCountries && state.allCountries.length) {
      return
    }

    // get from temporary local storage first
    const tempData = getTemp('settings-all-countries')

    // commit the data from temporary local storage if any
    if (tempData) {
      commit('setCountries', tempData)
      return
    }

    // get from API if needed
    const params = stringify({
      page: 1,
      per_page: 1000,
      ignore_per_page: true,
      order_by: 'sort asc',
    })
    try {
      const {
        data: { data },
      } = await this.$axios.get(`settings/countries?${params}`)
      commit('setCountries', data)

      setTemp('settings-all-countries', data)
    } catch (error) {
      showError(error, 'Get countries failed!')
    }
  },
  // eslint-disable-next-line consistent-return
  async getLanguages({ commit, state }) {
    // do not reload if the data is committed
    if (state.languages && state.languages.length) {
      return state.languages
    }

    // get from temporary local storage first
    const tempData = getTemp('settings-all-languages')

    // commit the data from temporary local storage if any
    if (tempData) {
      commit('setLanguages', tempData)
      return tempData
    }

    // get from API if needed
    const params = stringify({
      page: 1,
      per_page: 1000,
      ignore_per_page: true,
      order_by: 'sort asc',
    })
    try {
      const {
        data: { data },
      } = await this.$axios.get(`settings/languages?${params}`)
      commit('setLanguages', data)

      setTemp('settings-all-languages', data)
      return data
    } catch (error) {
      showError(error, 'Get languages failed!')
    }
  },
  async getBanners({ commit }, filters) {
    const { type } = filters
    let params = stringify(filters)

    if (type === 'gamerSpotlight') {
      params = stringify({ type: 'featured' })
      commit('setLoadingGamerSpotlight', true)
    } else {
      commit(`setLoading${capitalize(type)}Banner`, true)
    }

    try {
      const {
        data: { data },
      } = await this.$axios.get(`settings/cms/banners?${params}`)
      if (type === 'gamerSpotlight') {
        commit('setGamerSpotlight', data)
      } else {
        commit(`set${capitalize(type)}Banners`, data)
      }
    } catch (error) {
      showError(error, 'Get banner failed!')
    } finally {
      if (type === 'gamerSpotlight') {
        commit('setLoadingGamerSpotlight', false)
      } else {
        commit(`setLoading${capitalize(type)}Banner`, false)
      }
    }
  },
  async getMediaShowreels({ commit, dispatch }, filters) {
    commit('setLoadingMediaShowreel', true)
    const params = stringify(filters, { indices: false })
    try {
      const {
        data: { data },
      } = await this.$axios.get(`users/media?${params}`)

      const games = await Promise.all(
        data
          .filter(item => get(item, 'userGame.gameID') != null)
          .map(item => dispatch('catalogs/getGameById', item.userGame.gameID, { root: true })),
      )
      const showreels = data.map(item => {
        const game = games.find(g => g.id === get(item, 'userGame.gameID'))
        return {
          ...item,
          game,
        }
      })
      commit('setMediaShowreels', showreels)
    } catch (error) {
      showError(error, 'Get media failed!')
    } finally {
      commit('setLoadingMediaShowreel', false)
    }
  },
  async getSections({ commit }, filters) {
    const params = stringify(filters)

    try {
      const response = await this.$axios.get(`settings/sections?${params}`)
      const sections = get(response, 'data.data') || []
      const userIDs = uniq(sections.map(item => item.data.userIDs || [])).flat()
      const res = await Promise.all(
        userIDs.map(id => this.$axios.get(`users/${id}/combine-info`).catch(err => err)),
      )
      const users = res.map(item => get(item, 'data.data') || [])
      const dataUser = sections.map(i => i.data.userIDs.reduce((prev, item) => {
        const user = users.find(u => u.user.id === item)
        return [
          ...prev,
          {
            ...user,
          },
        ]
      }, []))
      const sectionsData = sections.reduce((prev, item, index) => [
        ...prev,
        {
          ...item,
          data: {
            userIDs: dataUser[index],
          },
        },
      ], [])
      commit('setSections', sectionsData)
    } catch (error) {
      showError(error, 'Get sections failed!')
    } finally {
      commit('setLoadingSections', false)
    }
  },
  async getCountryUser({ commit }) {
    try {
      const res = await this.$axios.get('/settings/country')
      commit('setCountryUser', res.data.data.country_code)
    } catch (error) {
      console.error(error)

      // set default country
      commit('setCountryUser', 'SG')
    }
  },
  async getTiers({ commit, state }) {
    // do not reload if the data is committed
    if (state.tiers && state.tiers.length) {
      return
    }

    // get from temporary local storage first
    const tempData = getTemp('settings-tiers-data')

    // commit the data from temporary local storage if any
    if (tempData) {
      commit('setTiers', tempData)
      return
    }

    // get from API if needed
    try {
      const {
        data: { data },
      } = await this.$axios.get('/settings/tiers')
      commit('setTiers', data)

      setTemp('settings-tiers-data', data)
    } catch (error) {
      showError(error)
    }
  },
  async getClaimedDiscord({ commit, state }, isRefreshed) {
    if (this.$auth.loggedIn && (state.claimDiscord == null || isRefreshed)) {
      const {
        data: { data },
      } = await this.$axios.get('webhook/discord/me/creators')
      commit('setClaimDiscord', data)
    }
  },
  async getInterest({ commit }) {
    try {
      const {
        data: { data },
      } = await this.$axios.get('/users/interest')
      commit('setInterest', data)
    } catch (error) {
      showError(error)
    }
  },
}
