import get from 'lodash/get'
import stringify from 'qs/lib/stringify'
import { formatMoney, formatLocale, showError } from '~/utils'
import { message } from 'ant-design-vue'
import Endpoint from '~/repository/endpoint'

export default {
  async loadServices({ commit }, params) {
    commit('setLoading', true)
    try {
      const res = await this.$axios.get(
        `catalogs/services?${stringify(params, { indices: false })}`,
      )
      const services = get(res, 'data.data') || []
      commit('setServices', services)
    } catch (error) {
      showError(error, 'Get services failed!')
      window.$nuxt.error({
        statusCode: 404,
        message: 'This page could not be found',
      })
    } finally {
      commit('setLoading', false)
    }
  },
  async getService({ commit }, slug) {
    try {
      const {
        data: { data: service },
      } = await this.$axios.get(`catalogs/services/slug/${slug}`)
      commit('setService', service)
    } catch (error) {
      showError(error, 'Get service failed!')
      throw error
    }
  },
  async updateService(_ctx, { id, payload }) {
    try {
      await this.$axios.put(`catalogs/services/${id}`, payload)
      message.success('Update service successfully!')
    } catch (error) {
      showError(error, 'Update service failed!')
      throw error
    }
  },
  async deleteService(_ctx, id) {
    try {
      await this.$axios.delete(`catalogs/services/${id}`)
      message.success('Delete service successfully!')
    } catch (error) {
      showError(error, 'Delete service failed!')
      throw error
    }
  },
  async getUnitListing({ commit }) {
    try {
      const {
        data: { data },
      } = await this.$axios.get('/catalogs/services/unittype')
      commit('setUnitListing', data)
    } catch (error) {
      showError(error, 'Unable to get unit type of listing')
    }
  },
  async getConvertedPrice(context, { price, currencies, country }) {
    let amount = ''
    let currency = ''

    if (currencies) {
      const payload = {
        usecases: 'TOTAL_CHARGE_LOWEST',
        original_price: price,
        original_price_currency: 'USD',
        target_price_currency: currencies,
      }

      const authToken = await this.$auth.strategy.token.get() // Adjust for context
      const { data } = await this.$axios.get(`${Endpoint.paymentInfoV2}?${stringify(payload, { skipNulls: true })}`, {
        baseURL: Endpoint.apiUrl,
        headers: authToken ? { Authorization: authToken } : {},
      })

      amount = data?.fees_total_charged_lowest?.target_price || 0
      currency = data?.fees_total_charged_lowest?.target_price_currency || 'USD'
    } else {
      amount = price
      currency = currencies
    }

    const finalPrice = formatMoney(
      amount,
      formatLocale(country),
      currency,
    )

    return finalPrice
  },
}
