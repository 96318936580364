export default {
  setLoading(state, loading) {
    state.loading = loading
  },
  setServices(state, data) {
    state.services = data
  },
  setService(state, data) {
    state.service = data
  },
  setProfiles(state, data) {
    state.profiles = data
  },
  setGames(state, data) {
    state.games = data
  },
  setUnitListing(state, data) {
    state.unitListing = data
  },
}
