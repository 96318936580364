export const ORDER_STATUS_VERIFYING = 'verifying'
export const ORDER_STATUS_CREATED = 'created'
export const ORDER_STATUS_ACCEPTED = 'accepted'
export const ORDER_STATUS_STARTED = 'started'
export const ORDER_STATUS_START_PENDING = 'start_pending'
export const ORDER_STATUS_START_CANCELLED = 'start_cancelled'
export const ORDER_STATUS_COMPLETED = 'completed'
export const ORDER_STATUS_CANCELLED = 'cancelled'
export const ORDER_STATUS_DONE = 'done'
export const ORDER_STATUS_COMPLETED_PENDING = 'completed_pending'
export const ORDER_STATUS_ONHOLD = 'onhold'
export const ORDER_STATUS_DISPUTE = 'dispute'
export const ORDER_STATUS_REJECTED = 'rejected'

export const ORDER_STATUSES = {
  [ORDER_STATUS_VERIFYING]: 'Verifying',
  [ORDER_STATUS_CREATED]: 'Created',
  [ORDER_STATUS_ACCEPTED]: 'Accepted',
  [ORDER_STATUS_STARTED]: 'Started',
  [ORDER_STATUS_START_PENDING]: 'Start Pending',
  [ORDER_STATUS_START_CANCELLED]: 'Start Cancelled',
  [ORDER_STATUS_COMPLETED]: 'Completed',
  [ORDER_STATUS_CANCELLED]: 'Cancelled',
  [ORDER_STATUS_DONE]: 'Done',
  [ORDER_STATUS_COMPLETED_PENDING]: 'Completed Pending',
  [ORDER_STATUS_ONHOLD]: 'On Hold',
  [ORDER_STATUS_DISPUTE]: 'Dispute',
  [ORDER_STATUS_REJECTED]: 'Rejected',
}

export const BASE_ORDER_STATUS_MAPPING = {
  [ORDER_STATUS_CREATED]: 'pendingAccept',
  [ORDER_STATUS_CANCELLED]: 'cancelled',
  [ORDER_STATUS_COMPLETED]: 'completed',
  [ORDER_STATUS_ACCEPTED]: 'accepted',
  [ORDER_STATUS_COMPLETED_PENDING]: 'completePending',
  // support old order statuses
  [ORDER_STATUS_START_PENDING]: 'pendingStart',
  [ORDER_STATUS_STARTED]: 'pendingJoin',
  [ORDER_STATUS_REJECTED]: 'rejected',
}

export const BUYER_ORDER_STATUS_MAPPING = {
  ...BASE_ORDER_STATUS_MAPPING,
}

export const SELLER_ORDER_STATUS_MAPPING = {
  ...BASE_ORDER_STATUS_MAPPING,
}

// Support order v2
export const ORDER_STATUS_TRANSITION = {
  [ORDER_STATUS_VERIFYING]: [ORDER_STATUS_CREATED],
  [ORDER_STATUS_CREATED]: [ORDER_STATUS_ACCEPTED, ORDER_STATUS_CANCELLED],
  [ORDER_STATUS_ACCEPTED]: [
    ORDER_STATUS_CANCELLED,
    ORDER_STATUS_COMPLETED_PENDING,
    ORDER_STATUS_ONHOLD,
  ],
  [ORDER_STATUS_COMPLETED_PENDING]: [ORDER_STATUS_COMPLETED],
  [ORDER_STATUS_ONHOLD]: [ORDER_STATUS_DISPUTE, ORDER_STATUS_ACCEPTED],
  [ORDER_STATUS_CANCELLED]: [ORDER_STATUS_DONE],
  [ORDER_STATUS_COMPLETED]: [ORDER_STATUS_DONE],
  // old data
  [ORDER_STATUS_STARTED]: [
    ORDER_STATUS_COMPLETED_PENDING,
    ORDER_STATUS_CANCELLED,
    ORDER_STATUS_ONHOLD,
  ],
  [ORDER_STATUS_START_PENDING]: [
    ORDER_STATUS_CANCELLED,
    ORDER_STATUS_COMPLETED_PENDING,
    ORDER_STATUS_ONHOLD,
  ],
  [ORDER_STATUS_START_CANCELLED]: [
    ORDER_STATUS_CANCELLED,
    ORDER_STATUS_COMPLETED_PENDING,
    ORDER_STATUS_ONHOLD,
  ],
}

export const getBuyerActionByStatus = (status) => {
  switch (status) {
    case ORDER_STATUS_COMPLETED_PENDING:
      return { text: 'Complete', status: ORDER_STATUS_COMPLETED }

    default:
      return {}
  }
}

export const getSellerActionByStatus = (status) => {
  switch (status) {
    case ORDER_STATUS_CREATED:
      return { text: 'Accept', status: ORDER_STATUS_ACCEPTED }

    case ORDER_STATUS_ACCEPTED:
      return { text: 'Complete', status: ORDER_STATUS_COMPLETED_PENDING }

    default:
      return {}
  }
}

export const orderStatusLevel = [
  {
    level: 0,
    status: 'created',
  },
  {
    level: 1,
    status: 'accepted',
  },
  {
    level: 2,
    status: 'completed_pending',
  },
]
