import stringify from 'qs/lib/stringify'
import { showError } from '~/utils'

export default {
  async getCategories({ commit }) {
    try {
      const {
        data: { data },
      } = await this.$axios.get('catalogs/categories')

      commit('setCategories', data)
      return data
    } catch (error) {
      showError(error, 'Get categories failed!')
    }
    return []
  },
  async getCategoryById({ commit }, Id) {
    try {
      const {
        data: { data },
      } = await this.$axios.get(`catalogs/categories/${Id}`)
      commit('setCategory', data)
      return data
    } catch (error) {
      showError(error, 'Get categories failed!')
    }
    return null
  },
  async getGames({ commit }) {
    const params = stringify({
      page: 1,
      per_page: 100,
      isActive: true,
      ignore_per_page: true,
    })
    try {
      const {
        data: { data },
      } = await this.$axios.get(`catalogs/games?${params}`)
      const games = data.map(item => ({ ...item, gameID: item.id }))
      commit('setGames', games)
      return games
    } catch (error) {
      showError(error, 'Get games failed!')
    }
    return []
  },
  async getGameById(id) {
    try {
      const {
        data: { data },
      } = await this.$axios.get(`catalogs/games/${id}`)
      return data
    } catch (error) {
      showError(error, 'Get game failed!')
    }
    return null
  },
  async getRecommendations({ commit }) {
    commit('setLoadingRecommendations', true)

    try {
      const {
        data: { data },
      } = await this.$axios.get('catalogs/recommendations?per_page=9')
      const responses = await Promise.all(
        data.map(({ serviceID }) => this.$axios.get(`catalogs/services/${serviceID}`)),
      )
      const services = data.map((item, index) => ({
        ...item,
        service: responses[index].data.data,
      }))
      commit('setRecommendations', services)
    } catch (error) {
      showError(error, 'Get banner failed!')
    } finally {
      commit('setLoadingRecommendations', false)
    }
  },
  async getUserServices({ commit }, userId) {
    try {
      let params = {
        userId,
        order_by: 'sort asc',
      }

      if ((this.$auth.loggedIn && userId !== this.$auth.user.id) || !this.$auth.loggedIn) {
        params = {
          ...params,
          isActive: true,
        }
      }

      const {
        data: { data },
      } = await this.$axios.get(
        'catalogs/services',
        { params },
      )
      commit('setUserServices', data)
      return data
    } catch (error) {
      showError(error, 'Get user services failed!')
    }
    return []
  },
  async getUserServicesByTag({ commit }, { userId, tagId }) {
    try {
      let params = {
        userId,
        order_by: 'sort asc',
      }

      if ((this.$auth.loggedIn && userId !== this.$auth.user.id) || !this.$auth.loggedIn) {
        params = {
          ...params,
          isActive: true,
        }
      }

      const {
        data: { data },
      } = await this.$axios.get(
        `catalogs/services?tagID=${tagId}`,
        { params },
      )

      commit('setUserServices', data)
      return data
    } catch (error) {
      showError(error, 'Get user services failed!')
    }
    return []
  },

  async getServices({ commit }, {
    userId, catalog, tagId, page = 1,
  }) {
    try {
      const params = {
        userId,
        page,
        per_page: 8,
      }

      const isUserLoggedIn = this.$auth.loggedIn
      const isDifferentUser = userId !== this.$auth?.user?.id

      if (!isUserLoggedIn || (isUserLoggedIn && isDifferentUser)) {
        params.isActive = true
      }

      if (catalog) {
        params.catalogs = catalog
      }

      if (tagId) {
        params.tagID = tagId
      }

      const paramQuery = Object.keys(params)
        .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`)
        .join('&')
      const response = await this.$axios.get(`catalogs/services?${paramQuery}&order_by=sort%20asc&order_by=createdAt%20desc`)
      const { data } = response.data

      if (data.length > 0) {
        if (catalog && catalog === 'service') {
          commit('setUserCommissions', data)
        } else {
          commit('setUserServices', data)
        }
      }
      return data
    } catch (error) {
      showError(error, 'Get user services failed!')
      return []
    }
  },
  async getUserGames({ commit, state, dispatch }) {
    try {
      const {
        data: { data },
      } = await this.$axios.get(`/users/${this.$auth.user.id}/games`)

      const isDataExists = state.serviceGames.length > 0
      // this function is just for replacing existing data to prevent
      // recall the game detail api
      if (isDataExists) {
        const userGames = state.serviceGames
        for (let i = 0; i < data.length; i += 1) {
          for (let j = 0; j < userGames.length; j += 1) {
            if (data[i].id === userGames[j].id) {
              data[i] = userGames[j]
            }
          }
        }
      }
      const games = await Promise.all(
        data
          .filter(e => typeof e.game === 'undefined')
          .map(item => dispatch('catalogs/getGameById', item.gameID, { root: true })),
      )

      const filterGames = games.filter(e => typeof e !== 'undefined')

      for (let i = 0; i < filterGames.length; i += 1) {
        for (let j = 0; j < data.length; j += 1) {
          if (filterGames[i].id === data[j].gameID) {
            data[j].game = filterGames[i]
          }
        }
      }

      commit('setServiceGames', data)
      return data
    } catch (error) {
      showError(error, 'Get user games failed!')
    }
    return []
  },
  async updateUserGame({ dispatch }, userGames) {
    try {
      const games = userGames.map(item => {
        const {
          gameID, skillLevel, summary, yearsPlayed,
        } = item
        return {
          gameID,
          skillLevel,
          summary,
          yearsPlayed,
        }
      })

      await this.$axios.put(`/users/${this.$auth.user.id}/games`, {
        games,
      })

      dispatch('getUserGames')
    } catch (error) {
      showError(error, 'Update user game failed!')
    }
  },
  async getInterests({ commit }) {
    try {
      const {
        data: { data },
      } = await this.$axios.get('users/interest')

      commit('setInterests', data)
      return data
    } catch (error) {
      showError(error, 'Get interests failed!')
    }
    return []
  },
}
