
const LayoutFooter = () => import('~/components/Layouts/LayoutFooter.vue')
const LayoutHeader = () => import('~/components/Layouts/LayoutHeader.vue')
const ScrollTop = () => import('~/components/Button/ScrollTop.vue')
const PaymentLoading = () => import('~/components/PaymentLoading/index.vue')

export default {
  components: {
    LayoutFooter, LayoutHeader, ScrollTop, PaymentLoading,
  },
}
