import { showError } from '~/utils'
import stringify from 'qs/lib/stringify'

export default {
  async getEventList({ commit }, filters) {
    commit('setLoading', true)
    try {
      const params = stringify(filters)
      const {
        data: { data },
      } = await this.$axios.get(`events?${params}`)

      commit('setLoading', false)
      commit('setEvents', data)
    } catch (error) {
      commit('setLoading', false)
      showError(error, 'Failed to load current location!')
    }
  },
}
