import CryptoJS from 'crypto-js'
import dayjs from 'dayjs'
import get from 'lodash/get'

export default async function _({
  query, $axios, $auth,
}) {
  const token = get(query, 'gankToken')

  if (token) {
    try {
      const useDateTime = get(query, 'useDateTime')
      let key = CryptoJS.enc.Utf8.parse(process.env.NUXT_ENV_CRYPTO_KEY)
      if (useDateTime) {
        const date = dayjs().format('YYYYMMDDHH')
        key = CryptoJS.enc.Utf8.parse(process.env.NUXT_ENV_CRYPTO_KEY_NEW + date)
      }
      const iv = CryptoJS.enc.Utf8.parse(process.env.NUXT_ENV_CRYPTO_IV)
      const aesDecryptor = CryptoJS.AES.decrypt(decodeURI(token), key, {
        iv,
        mode: CryptoJS.mode.CBC,
      })

      await $auth.strategy.token.set(aesDecryptor.toString(CryptoJS.enc.Utf8))
      const {
        data: { data: user },
      } = await $axios.get('users/me')
      await $auth.setUser(user)
      await $auth.$storage.setState('loggedIn', true)
    } catch (error) {
    }
  }
  return true
}
