export const getTemp = (key, fallback = null) => {
  const currentAppVersion = process.env.NUXT_ENV_APP_VERSION
  const itemStr = localStorage.getItem(`temp__${key}`)
  // if the item doesn't exist, return null
  if (!itemStr) {
    return fallback
  }
  const item = JSON.parse(itemStr)
  const now = new Date()
  // compare the expiry time of the item with the current time
  if (item.app_version !== currentAppVersion || now.getTime() > item.expiry) {
    // If the item is expired, delete the item from storage
    // and return null
    localStorage.removeItem(`temp__${key}`)
    return null
  }
  return item.value
}

// default ttl is 30 days(in milliseconds)
export const setTemp = (key, value, ttl = 30 * 24 * 60 * 60 * 1000) => {
  const currentAppVersion = process.env.NUXT_ENV_APP_VERSION
  const now = new Date()

  // `item` is an object which contains the original value
  // as well as the time when it's supposed to expire
  const item = {
    value,
    app_version: currentAppVersion,
    expiry: now.getTime() + ttl,
  }

  localStorage.setItem(`temp__${key}`, JSON.stringify(item))
}
