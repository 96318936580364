const userData = async ($axios, $auth) => {
  const config = {}

  if ($auth.loggedIn) {
    const authToken = await $auth.strategy.token.get()
    config.headers = { Authorization: authToken }

    $axios.get({
      url: '/users/me',
      ...config,
    })
      .then(data => {
        const user = data.data.data
        $auth.setUser(user)
      })
  }
}

export default userData
