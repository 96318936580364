
export default {
  name: 'AnnouncementBanner',
  components: {},
  data() {
    return {
      isActive: false,
      isBannerDismissed: true,
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.isMounted = true
      setTimeout(() => {
        if (this.$cookies.get('announcement-banner')) {
          this.isBannerDismissed = true
        } else {
          this.isBannerDismissed = false
        }
      }, 2000)
    })
  },
  methods: {
    dismissBanner() {
      this.$cookies.set('announcement-banner', 'true')
      this.isBannerDismissed = true
    },
    findOutMoreCta() {
      this.$gtm.push({
        event: 'gank_anniversary_top_up_giveaway_campaign',
      })
    },
  },
}
