export default () => ({
  stripeAccount: null,
  paymentMethods: [],
  defaultPaymentMethodId: '',
  defaultPaymentMethodType: 'card',
  defaultPaymentMethodXendit: null,
  defaultPaymentMethodPepay: null,
  ephemeralData: null,
  paymentConfig: null,
  topupConfig: null,
  withdrawalConfig: null,
  transactionToCheck: null,
  checkStatusInterval: null,
  loading: false,
  paymentLoading: false,
  promptpayData: null,
})
