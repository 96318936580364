import get from 'lodash/get'
import find from 'lodash/find'

const getters = {
  getCreditWallet: state => find(state.wallets, { type: 'credit' }),
  getEarningWallet: state => find(state.wallets, { type: 'earning' }),
  getNewBalance: state => {
    const selectedWallet = find(state.wallets, { type: 'credit' })
    const balance = get(selectedWallet, 'balance')
    return balance - state.newBalance
  },
  getCreditBalance: state => {
    const selectedWallet = find(state.wallets, { type: 'credit' })
    return get(selectedWallet, 'balance')
  },
  getEarningBalance: state => {
    const selectedWallet = find(state.wallets, { type: 'earning' })
    return get(selectedWallet, 'balance')
  },
  getCreditTransactions: state => state.transactions.filter(t => get(t, 'wallet.type') === 'credit'),
  getEarningTransactions: state => state.transactions.filter(t => get(t, 'wallet.type') === 'earning'),
  getconfirmationAccount: state => get(state, 'confirmationAccount'),
}

export default getters
