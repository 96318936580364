import get from 'lodash/get'
import { PAYMENT_METHOD_TYPES } from '~/utils'

const getters = {
  /**
   *
   * @param {*} state
   * @returns stripe account's default payment method
   */
  getDefaultPaymentMethod: state => get(state, 'stripeAccount.defaultPaymentMethod'),
  /**
   *
   * @param {*} state
   * @returns current payment method item
   */
  getCurrentPaymentMethodItem: state => {
    const paymentMethodId = state.defaultPaymentMethodId
    const paymentMethodType = state.defaultPaymentMethodType

    if (paymentMethodType === PAYMENT_METHOD_TYPES.GRABPAY) {
      return { id: null, paymentMethodType }
    }

    if (paymentMethodType === PAYMENT_METHOD_TYPES.STRIPE_WECHAT_PAY) {
      return { id: null, paymentMethodType }
    }

    if (paymentMethodType === PAYMENT_METHOD_TYPES.STRIPE_PAYNOW) {
      return { id: null, paymentMethodType }
    }

    if (paymentMethodType === PAYMENT_METHOD_TYPES.DRAGONPAY_GCASH) {
      return { id: null, paymentMethodType }
    }

    if (paymentMethodType === PAYMENT_METHOD_TYPES.DRAGONPAY_PAYMAYA) {
      return { id: null, paymentMethodType }
    }

    if (paymentMethodType === PAYMENT_METHOD_TYPES.EARNINGS) {
      return { id: null, paymentMethodType }
    }

    if (paymentMethodType === PAYMENT_METHOD_TYPES.GCASH) {
      return { id: null, paymentMethodType }
    }

    if (paymentMethodType === PAYMENT_METHOD_TYPES.PAYPAL) {
      return { id: null, paymentMethodType }
    }

    if (paymentMethodType === PAYMENT_METHOD_TYPES.SENANGPAY) {
      return { id: null, paymentMethodType }
    }

    if (paymentMethodType === PAYMENT_METHOD_TYPES.IPAYMU) {
      return { id: null, paymentMethodType }
    }

    if (paymentMethodType === PAYMENT_METHOD_TYPES.XENDIT) {
      return { id: null, paymentMethodType }
    }

    if (paymentMethodType === PAYMENT_METHOD_TYPES.ZALOPAY_WALLET) {
      return { id: null, paymentMethodType }
    }

    if (paymentMethodType === PAYMENT_METHOD_TYPES.ZALOPAY_VIETQR) {
      return { id: null, paymentMethodType }
    }

    if (paymentMethodType === PAYMENT_METHOD_TYPES.ZALOPAY_LOCALBANK) {
      return { id: null, paymentMethodType }
    }

    if (paymentMethodType === PAYMENT_METHOD_TYPES.PEPAY_LINEPAY) {
      return { id: null, paymentMethodType }
    }

    if (paymentMethodType === PAYMENT_METHOD_TYPES.PEPAY_JKO) {
      return { id: null, paymentMethodType }
    }

    if (paymentMethodType === PAYMENT_METHOD_TYPES.PEPAY_CONVENIENCE_STORE_FAMI) {
      return { id: null, paymentMethodType }
    }

    if (paymentMethodType === PAYMENT_METHOD_TYPES.PEPAY_CONVENIENCE_STORE_IBON) {
      return { id: null, paymentMethodType }
    }

    if (paymentMethodType === PAYMENT_METHOD_TYPES.PEPAY_CONVENIENCE_STORE_MBC) {
      return { id: null, paymentMethodType }
    }

    if (paymentMethodType === PAYMENT_METHOD_TYPES.PEPAY_ATM_CTCB) {
      return { id: null, paymentMethodType }
    }

    if (paymentMethodType === PAYMENT_METHOD_TYPES.PEPAY_ATM_ESUN) {
      return { id: null, paymentMethodType }
    }

    if (paymentMethodType === PAYMENT_METHOD_TYPES.PEPAY_ATM_HNCB) {
      return { id: null, paymentMethodType }
    }

    if (paymentMethodType === PAYMENT_METHOD_TYPES.PEPAY_ATM_SCSB) {
      return { id: null, paymentMethodType }
    }

    if (paymentMethodType === PAYMENT_METHOD_TYPES.XENDIT_CREDIT_CARD) {
      return { id: null, paymentMethodType }
    }

    if (paymentMethodType === PAYMENT_METHOD_TYPES.XENDIT_INTERNET_BANKING) {
      return { id: null, paymentMethodType }
    }

    if (paymentMethodType === PAYMENT_METHOD_TYPES.XENDIT_DANA) {
      return { id: null, paymentMethodType }
    }

    if (paymentMethodType === PAYMENT_METHOD_TYPES.XENDIT_OVO) {
      return { id: null, paymentMethodType }
    }

    if (paymentMethodType === PAYMENT_METHOD_TYPES.XENDIT_QRIS) {
      return { id: null, paymentMethodType }
    }

    if (paymentMethodType === PAYMENT_METHOD_TYPES.SIAMPAY_QR_PROMPTPAY) {
      return { id: null, paymentMethodType }
    }

    if (paymentMethodType === PAYMENT_METHOD_TYPES.SIAMPAY_TRUEMONEY) {
      return { id: null, paymentMethodType }
    }

    if (paymentMethodType === PAYMENT_METHOD_TYPES.STRIPE_GOOGLE_PLAY) {
      return { id: null, paymentMethodType }
    }

    if (paymentMethodType === PAYMENT_METHOD_TYPES.STRIPE_APPLEPAY) {
      return { id: null, paymentMethodType }
    }

    if (paymentMethodType === PAYMENT_METHOD_TYPES.ELEMENTPAY) {
      return { id: null, paymentMethodType }
    }

    const obj = state.paymentMethods.find(pm => pm.id === paymentMethodId)
    return obj ? { ...obj, paymentMethodType } : undefined
  },
  /**
   *
   * @param {*} state
   * @returns API key that's used to authenticate via bearer auth
   */
  getEphemeralKey: state => get(state, 'ephemeralData.secret'),
  /**
   *
   * @param {*} state
   * @returns stripe's customer id
   */
  getStripeAccountID: state => get(state, 'stripeAccount.stripeAccountID'),
  getPaymentConfig: state => get(state, 'paymentConfig'),
  getTopupConfig: state => get(state, 'topupConfig'),
  getWithdrawalConfig: state => get(state, 'withdrawalConfig'),
}

export default getters
