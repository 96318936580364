
const PaymentLoading = () => import('~/components/PaymentLoading/index.vue')

export default {
  components: {
    PaymentLoading,
  },
  data() {
    return {
      loading: true,
    }
  },
  mounted() {
    this.$nextTick(() => {
      setTimeout(() => {
        this.loading = false
      }, [500])
    })
  },
}
