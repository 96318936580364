import { showError } from '~/utils'

export default {
  async fetchPosts(_, params) {
    const filter = {
      author: this.$auth.user.id,
      searchKeyword: params.searchKeyword,
      page: params.page,
      perPage: params.per_page,
      postAccessTypes: params.postAccessTypes,
      userMembershipId: params.userMembershipId,
      scheduling: params.scheduling,
      orderBy: params.orderBy,
      excludePinned: true,
    }
    const cleanedFilter = Object.entries(filter).reduce((acc, [key, value]) => {
      if (value !== undefined) {
        acc[key] = value
      }
      return acc
    }, {})

    let posts

    // eslint-disable-next-line no-unused-vars
    const paramQuery = new URLSearchParams(cleanedFilter).toString()
    try {
      const response = await this.$axios.get(`/posts?${paramQuery}`)

      posts = {
        items: response?.data?.data || [],
        total: response?.data?.metadata?.total || 0,
      }
    } catch (error) {
      showError(error)
    }
    return posts
  },
  async getPinnedPostsByUserId(_, userId) {
    if (!userId) {
      return []
    }

    let pinnedPosts = []
    try {
      const response = await this.$axios.get(`/posts/pinned-post/${userId}`)
      pinnedPosts = response?.data?.data || []
    } catch (error) {
      showError(error)
    }
    return pinnedPosts
  },
  async setPinnedPosts(_, id) {
    try {
      await this.$axios.post('/posts/pinned-post', {
        id,
      })
    } catch (error) {
      showError(error)
    }
  },
  async setUnpinnedPosts(_, id) {
    try {
      await this.$axios.patch('/posts/pinned-post', {
        id,
      })
    } catch (error) {
      showError(error)
    }
  },
}
