export default {
  setIsLoading(state, value) {
    state.isLoading = value
  },
  setIsEnable2FA(state, value) {
    state.isEnable2FA = value
  },
  setIsClickFromDashboard(state, value) {
    state.isClickFromDashboard = value
  },
  setShowInvalidCode(state, value) {
    state.showInvalidCode = value
  },
  setShowOTPLogin(state, value) {
    state.showOTPLogin = value
  },
  setRemainingTime(state, time) {
    state.remainingTime = time
  },
  setDisabledResend(state, isDisabled) {
    state.isDisableResend = isDisabled
  },
}
