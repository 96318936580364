export default {
  UPDATE_STEP(state, val) {
    if (val <= state.completedStep + 1) {
      state.step = val
    }
  },
  UPDATE_COMPLETED_STEP(state, val) {
    state.completedStep = val
  },
  UPDATE_EMAIL(state, val) {
    state.email = val
  },
}
