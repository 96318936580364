import debounce from 'lodash/debounce'

class AgoraService {
  client = null

  vm = null

  localStream = null

  constructor(vm) {
    if (AgoraService.instance) {
      return AgoraService.instance
    }
    AgoraService.instance = this
    this.vm = vm
  }

  initialize() {
    if (!this.client) {
      this.vm.$agoraRTC.Logger.setLogLevel(4)
      this.client = this.vm.$agoraRTC.createClient({ mode: 'rtc', codec: 'vp8' })
      this.client.init(this.vm.$config.agoraAppId, () => {
        this.client.on('stream-added', evt => {
          this.client.subscribe(evt.stream)
        })

        this.client.on('stream-subscribed', evt => {
          const { stream } = evt
          if (!this.localStream) {
            this.localStream = stream
          }
          stream.play('agorastream')
        })

        this.client.on('peer-online', () => {
          this.vm.$store.commit('chat/setCallStarted', true)
        })

        this.client.on('mute-audio', () => {
          this.vm.$store.commit('chat/setPeerAudioMuted', true)
        })

        this.client.on('unmute-audio', () => {
          this.vm.$store.commit('chat/setPeerAudioMuted', false)
        })

        this.client.on('peer-leave', () => {
          this.leave()
        })
      })
    }
  }

  joinCall(token, channel, uid) {
    this.client.join(token, channel, uid, val => {
      // Create a local stream
      this.localStream = this.vm.$agoraRTC.createStream({
        streamID: val,
        audio: true,
        video: false,
      })
      this.localStream.init(() => {
        this.client.publish(this.localStream)
      })
    })
  }

  mute() {
    if (this.localStream) {
      return this.localStream.muteAudio()
    }
    return false
  }

  unmute() {
    if (this.localStream) {
      return this.localStream.unmuteAudio()
    }
    return false
  }

  leave() {
    const onLeave = debounce(() => {
      if (!this.localStream) return
      this.client.unsubscribe(this.localStream)
      this.client.unpublish(this.localStream)
      this.client.leave()
      this.localStream.removeTrack(this.localStream.getAudioTrack())
      this.vm.$store.commit('chat/setPeerAudioMuted', false)
      this.localStream = null
    }, 1000)

    onLeave()
  }

  getClient() {
    return this.client
  }
}

export default AgoraService
