
const GankLoadingRive = () => import('~/components/Rive/Gank.vue')

export default {
  name: 'Loader',
  components: {
    GankLoadingRive,
  },
  props: {
    isFull: Boolean,
  },
}
