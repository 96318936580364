const { createProxyMiddleware } = require('http-proxy-middleware')

// eslint-disable-next-line consistent-return
module.exports = function (req, res, next) {
  if (req.url.startsWith('/google-content')) {
    return createProxyMiddleware({
      target: 'https://lh3.googleusercontent.com',
      changeOrigin: true,
      pathRewrite: {
        '^/google-content': '',
      },
    })(req, res, next)
  }

  if (req.url.startsWith('/gank-cdn')) {
    return createProxyMiddleware({
      target: 'https://cdn.ganknow.com',
      changeOrigin: true,
      pathRewrite: {
        '^/gank-cdn': '',
      },
    })(req, res, next)
  }

  if (req.url.startsWith('/gank-staging-cdn')) {
    return createProxyMiddleware({
      target: 'https://cdn-staging.ganknow.com',
      changeOrigin: true,
      pathRewrite: {
        '^/gank-staging-cdn': '',
      },
    })(req, res, next)
  }
  next()
}
