// eslint-disable-next-line func-names
export default function (ctx) {
  // Check if ctx and $auth are properly defined

  if (typeof window !== 'undefined') {
    const { hostname } = window.location

    if (hostname === 'gamepal.gg' || hostname === 'ganknow.com') {
      const script = document.createElement('script')
      script.dataset.cfasync = false
      script.async = true
      script.src = 'https://fw-cdn.com/11366795/4047009.js'
      document.head.appendChild(script)
    }
  }

  if (!ctx || !ctx.$auth) {
    console.error('Auth context is not available')
    return
  }

  const { $auth } = ctx

  // Return early if the user is not logged in.
  if (!$auth.loggedIn) {
    return
  }

  // Destructure user details for easier access and provide defaults where necessary.
  const {
    id,
    firstName,
    email,
    nickname,
  } = $auth.user || {}

  // Ensure necessary user details are present
  if (!id || !email) {
    console.error('Missing required user details')
    return
  }

  function updateUserDetails() {
    // Ensure the Freshchat widget is available on the window object
    if (!window.fcWidget) {
      console.error('Freshchat widget is not loaded')
      return
    }

    window.fcWidget.setExternalId(id)
    window.fcWidget.user.setFirstName(firstName || nickname)
    window.fcWidget.user.setEmail(email)
  }

  // Configure the Freshchat widget settings
  if (!window.fcSettings) {
    window.fcSettings = {}
  }

  window.fcSettings.onInit = updateUserDetails
}
