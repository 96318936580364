import debounce from 'lodash/debounce'

export default {
  getNotifications: debounce(async function ({ commit }) {
    try {
      const {
        data: { data },
      } = await this.$axios.get('/nms', {
        params: {
          status: 'all',
          page: 1,
          per_page: 20,
        },
      })
      commit('setNotifications', [...data])
      commit('setHasUnreadNotifications', data.some(notif => !notif.isRead))
    } catch (error) {
      //
    } finally {
      const getOpenedNotif = localStorage.getItem('openedNotifications')
      if (getOpenedNotif) {
        commit('setOpenedNotifications', JSON.parse(getOpenedNotif))
      }
    }
  }, 1000),

  async setNotificationRead({ commit }) {
    try {
      await this.$axios.post('/nms/notification/read/all')
      commit('setAllNotificationsRead')
      commit('setHasUnreadNotifications', false)
    } catch (error) {
      console.error('Error set all notifications read', error)
    }
  },
  async setNotificationReadById({ commit }, id) {
    try {
      await this.$axios.post(`/nms/notification/read/${id}`)
      commit('setNotificationReadById', id)
    } catch (error) {
      console.error('Error set by id notifications', error)
    }
  },
}
