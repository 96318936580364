import { createUserBasedDeviceId } from '~/utils'

export default {
  methods: {
    async requestNotification() {
      const checkToken = JSON.parse(localStorage.getItem('notification'))
      if ('Notification' in window && !checkToken) {
        try {
          await Notification.requestPermission()
          if (Notification.permission === 'granted') {
            this.generateToken()
          } else if (Notification.permission === 'denied') {
            const permission = await Notification.requestPermission()
            if (permission === 'granted') {
              this.generateToken()
            }
          }
        } catch (error) {}
      } else if (checkToken && checkToken.nmsId) {
        this.patchToken(checkToken.nmsId)
      }
    },
    async generateToken() {
      try {
        await this.$fire.messagingReady()
        const token = await this.$fire.messaging.getToken()
        this.addNotification(token)
      } catch (_) {
      }
    },
    async patchToken(nmsId) {
      try {
        const token = await this.$fire.messaging.getToken()
        const checkToken = JSON.parse(localStorage.getItem('notification'))
        if (checkToken && checkToken.token !== token) {
          try {
            await this.$axios.patch(`/nms/tokens/${nmsId}`, { token })
            await this.$axios.post(`/nms/tokens/${nmsId}/users/sync`, {
              deviceId: this.getDeviceId(),
              deviceType: 'web',
            })
            localStorage.setItem('notification', JSON.stringify({ nmsId, token }))
          } catch (_) {}
        }
      } catch (_) {

      }
    },
    async addNotification(val) {
      try {
        const {
          data: { data: token },
        } = await this.$axios.post('/nms/tokens', {
          deviceId: this.getDeviceId(),
          deviceType: 'web',
        })
        await this.$axios.patch(`/nms/tokens/${token.id}`, { token: val })
        await this.$axios.post(`/nms/tokens/${token.id}/users/sync`, {
          deviceId: this.getDeviceId(),
          deviceType: 'web',
        })
        localStorage.setItem('notification', JSON.stringify({ nmsId: token.id, token: val }))
      } catch (error) {
        const { response } = error
        if (response.status !== 200) {
          try {
            await this.deleteNotification()
            this.generateToken()
          } catch (_) {}
        }
      }
    },
    async deleteNotification() {
      try {
        const checkToken = JSON.parse(localStorage.getItem('notification'))
        if (checkToken) {
          await this.$axios.post(`/nms/tokens/${checkToken.nmsId}/users/un-sync`, {
            deviceId: this.getDeviceId(),
            deviceType: 'web',
          })
          await this.$axios.delete(`/nms/tokens/${checkToken.nmsId}`)
        }
        await this.$fire.messaging.deleteToken()
      } catch (_) {}
    },
    getDeviceId() {
      try {
        const deviceId = localStorage.getItem('deviceId')
        if (deviceId) {
          return deviceId
        }
        const newDeviceId = createUserBasedDeviceId()
        localStorage.setItem('deviceId', newDeviceId)
        return newDeviceId
      } catch (error) {
        console.error('Error generating deviceId:', error)
        // Fallback to timestamp-based ID if something goes wrong
        return `fallback-${Date.now()}`
      }
    },
  },
}
