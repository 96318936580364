const countdownDuration = 60
let countdownInterval = null

export default {
  handleClick({ dispatch }) {
    const now = Date.now()
    localStorage.setItem('lastClickTime', now.toString())
    dispatch('startCountdown')
  },
  startCountdown({ dispatch, commit }) {
    commit('setDisabledResend', true)

    if (countdownInterval) {
      clearInterval(countdownInterval)
    }

    countdownInterval = setInterval(() => {
      const lastClickTime = parseInt(localStorage.getItem('lastClickTime'), 10)
      const timeElapsed = Math.floor((Date.now() - lastClickTime) / 1000)
      const remainingTime = countdownDuration - timeElapsed

      commit('setRemainingTime', remainingTime)

      if (remainingTime <= 0) {
        dispatch('clearOTPInterval')
      }
    }, 1000)
  },
  checkLastClickTime({ dispatch, commit }) {
    const lastClickTime = parseInt(localStorage.getItem('lastClickTime'), 10)

    if (lastClickTime) {
      const timeElapsed = Math.floor((Date.now() - lastClickTime) / 1000)
      if (timeElapsed < countdownDuration) {
        commit('setRemainingTime', countdownDuration - timeElapsed)
        dispatch('startCountdown')
      } else {
        localStorage.removeItem('lastClickTime')
      }
    }
  },
  clearOTPInterval({ commit }) {
    clearInterval(countdownInterval)
    countdownInterval = null
    commit('setDisabledResend', false)
    localStorage.removeItem('lastClickTime')
    commit('setRemainingTime', 0)
  },
}
