import { toObject } from '~/utils'

export default {
  setCachedAuthorDetailObject(state, data) {
    state.cachedAuthorDetails = Object.assign(state.cachedAuthorDetails, toObject(data, 'id'))
  },
  setGeneralPosts(state, data) {
    state.generalPosts = data
  },
  setFollowingPosts(state, data) {
    state.followingPosts = data
  },
}
