export default function sentry({ $auth, $sentry, store }) {
  // check user is exist and logged in
  if ($auth.loggedIn && $auth.user) {
    $sentry.setUser({
      id: $auth.user.id,
      email: $auth.user.email,
      username: $auth.user.nickname,
    })
    $sentry.setTag('user_type', $auth.user.userType)
  }
  if (store.state.settings.countryUser) {
    $sentry.setTag('country', store.state.settings.countryUser)
  }
  if (store.state.settings.locale) {
    $sentry.setTag('locale', store.state.settings.locale)
  }
}
