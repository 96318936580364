import sortBy from 'lodash/sortBy'
import { showError } from '~/utils'

export default {
  async getUserWallet({ commit }, id) {
    try {
      const {
        data: { data },
      } = await this.$axios.get(`/wallets?userID=${id}`)
      commit('setUserWallet', data)
    } catch (error) {
      showError(error, 'Get user wallet failed!')
    }
  },
  async getCreditPackages({ commit }) {
    try {
      const {
        data: { data },
      } = await this.$axios.get('/wallets/packages')
      commit('setPackages', sortBy(data, 'amount'))
    } catch (error) {
      showError(error, 'Get credit packages failed!')
    }
  },
  async getUserTransactions({ commit }, id) {
    try {
      const {
        data: { data },
      } = await this.$axios.get(`/wallets/transactions?userID=${id}&order_by=createdAt desc`)
      commit('setUserTransactions', data)
    } catch (error) {
      showError(error, 'Get user transactions failed!')
    }
  },
  setNewBalance({ commit }, balance) {
    commit('setNewBalance', balance)
  },
  async getUserTransactionDetails({ commit }, transactionID) {
    try {
      // handle v1 transaction ID from zalopay
      if (transactionID.length === 36) {
        const {
          data: { data },
        } = await this.$axios.get(
          `/wallets/transactions/detail?id=${transactionID}`,
        )
        this.$router.push(`/manage/payment-detail/${data.orderNumber}`)
        return
      }
      // check transaction id to differentiate recurring and one time transaction
      if (transactionID.startsWith('UT') || transactionID.startsWith('UM')) {
        const oneTimeURL = `/payment/transaction?human_id=${transactionID}`
        const recurringURL = `/payment/subscription?sub_human_id=${transactionID}`
        const isOneTimeMembership = (transactionID.startsWith('UTO') || transactionID.startsWith('UMO'))
        const { data } = await this.$apiV2.get(isOneTimeMembership ? oneTimeURL : recurringURL)
        if (data) {
          commit('setUserTransactionDetails', data)
          return
        }
        // handle if recurringURL return null
        const res = await this.$apiV2.get(oneTimeURL)
        commit('setUserTransactionDetails', res.data)
        return
      }
      // check for non subscription transaction
      if (!transactionID.startsWith('UT') && !transactionID.startsWith('UM')) {
        const res = await this.$apiV2.get(`/payment/transaction?human_id=${transactionID}`)
        commit('setUserTransactionDetails', res.data)
      }
    } catch (error) {
      showError(error, 'Get user transactions failed!')
    }
  },
}
