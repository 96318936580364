export default {
  setNotifications(state, data) {
    state.notifications = data
  },
  setOpenedNotifications(state, data) {
    state.openedNotifications = data
  },
  setHasUnreadNotifications(state, data) {
    state.hasUnreadNotifications = data
  },
  setAllNotificationsRead(state) {
    state.notifications = state.notifications.map(notification => ({
      ...notification,
      isRead: true,
    }))
  },
  setNotificationReadById(state, id) {
    state.notifications = state.notifications.map(notification => {
      if (notification.id === id) {
        return { ...notification, isRead: true }
      }
      return notification
    })
  },
}
