import createApp from './app.js'

import messagingService from './service.messaging.js'
import remoteConfigService from './service.remoteConfig.js'

const appConfig = {"apiKey":"AIzaSyAVNwfvu3haYSiNr_P1TjHGHFVW3LUj-TI","authDomain":"gank-global.firebaseapp.com","projectId":"gank-global","storageBucket":"gank-global.appspot.com","messagingSenderId":"854677098451","appId":"1:854677098451:web:0125f87a5f6583bf5c2926"}

export default async (ctx, inject) => {
  let firebase, session
  let firebaseReady = false

  const fire = {
    async appReady() {
      if (!firebaseReady) {
        ({ firebase, session } = await createApp(appConfig, ctx))
        firebaseReady = true;

        forceInject(ctx, inject, "fireModule", firebase)
      }
      return session
    },
    async ready() {
      await fire.appReady()

      let servicePromises = []

      if (process.client) {
        servicePromises = [
        fire.messagingReady(),
        fire.remoteConfigReady(),

        ]
      }

      await Promise.all(servicePromises)
      return session
    }
  }

  if (process.server) {
  }

  if (process.client) {
    fire.messaging = null
    fire.messagingReady = async () => {
      if (!fire.messaging) {
        await fire.appReady()
        fire.messaging = await messagingService(session, firebase, ctx, inject)
      }

      return fire.messaging
    }

    fire.remoteConfig = null
    fire.remoteConfigReady = async () => {
      if (!fire.remoteConfig) {
        await fire.appReady()
        fire.remoteConfig = await remoteConfigService(session, firebase, ctx, inject)
      }

      return fire.remoteConfig
    }
  }

  inject('fire', fire)
  ctx.$fire = fire
}

function forceInject (ctx, inject, key, value) {
  inject(key, value)
  const injectKey = '$' + key
  ctx[injectKey] = value
  if (typeof window !== "undefined" && window.$nuxt) {
  // If clause makes sure it's only run when ready() is called in a component, not in a plugin.
    window.$nuxt.$options[injectKey] = value
  }
}