import Vue from 'vue'
import VueGtag from 'vue-gtag'

export default ({ $config }) => {
  Vue.use(VueGtag, {
    config: {
      id: $config.gmtId,
    },
  })
}
