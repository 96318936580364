export default class Endpoint {
  static apiUrl = process.env.NUXT_ENV_API_URL

  static paymentV2 = '/v2/payment/transactions' // all-in-one endpoints

  static userPaymentV2 = '/v2/payment/user-info' // detail transaction

  static userPaymentPublicV2 = '/v2/payment/user-info/public' // detail transaction

  static subscriptionPaymentV2 = '/v2/payment/subscriptions' // subscription transaction

  static paymentInfoV2 = '/v2/payment/payment-info' // price calculation

  static detailTransactionV2 = '/v2/payment/transaction' // detail transaction

  static detailRecurringV2 = '/v2/payment/subscription' // detail transaction
}
