import sortBy from 'lodash/sortBy'
import { showError } from '~/utils'

export default {
  async getUserWallet({ commit }, id) {
    try {
      const {
        data: { data },
      } = await this.$axios.get(`/wallets?userID=${id}`)
      commit('setUserWallet', data)
    } catch (error) {
      showError(error, 'Get user wallet failed!')
    }
  },
  async getCreditPackages({ commit }) {
    try {
      const {
        data: { data },
      } = await this.$axios.get('/wallets/packages')
      commit('setPackages', sortBy(data, 'amount'))
    } catch (error) {
      showError(error, 'Get credit packages failed!')
    }
  },
  async getUserTransactions({ commit }, id) {
    try {
      const {
        data: { data },
      } = await this.$axios.get(`/wallets/transactions?userID=${id}&order_by=createdAt desc`)
      commit('setUserTransactions', data)
    } catch (error) {
      showError(error, 'Get user transactions failed!')
    }
  },
  setNewBalance({ commit }, balance) {
    commit('setNewBalance', balance)
  },
  async getUserTransactionDetails({ commit }, transactionID) {
    try {
      const {
        data: { data },
      } = await this.$axios.get(
        `/wallets/transactions/detail?id=${transactionID}`,
      )
      commit('setUserTransactionDetails', data)
    } catch (error) {
      showError(error, 'Get user transactions failed!')
    }
  },
}
