
import get from 'lodash/get'

const googlePlayDownloadButtonImage = require('~/assets/images/get-it-on-google-play.png')
const appStoreDownloadButtonImage = require('~/assets/images/download-on-the-app-store.png')

export default {
  data() {
    return {
      showAppDownloadInfo: false,
      downloadAppButton: [
        {
          key: 'app-store',
          image: appStoreDownloadButtonImage,
          alt: 'Download on the App Store',
          url: 'https://apps.apple.com/sg/app/creator-companion-app/id6446162254',
          class: 'w-[120px]',
        },
        {
          key: 'google-play',
          image: googlePlayDownloadButtonImage,
          alt: 'Get it on Google Play',
          url: 'https://play.google.com/store/apps/details?id=com.ganknow.app&hl=en&gl=US',
          class: 'w-[135px]',
        },
      ],
    }
  },
  computed: {
    userType() {
      return get(this.$auth, 'user.userType', 'creator')
    },
    wordingByUserType() {
      if (this.userType === 'creator') return this.$t('downloadAppWording.creator')
      return this.$t('downloadAppWording.supporter')
    },
    downloadAppButtonList() {
      if (this.$device.isIos) {
        return this.downloadAppButton.filter(button => button.key === 'app-store')
      }
      if (this.$device.isAndroid && this.$device.isMobileOrTablet) {
        return this.downloadAppButton.filter(button => button.key === 'google-play')
      }

      return this.downloadAppButton
    },
  },
  mounted() {
    this.$nextTick(() => {
      setTimeout(() => {
        if (this.$cookies.get('show-app-download')) {
          this.showAppDownloadInfo = false
        } else {
          this.showAppDownloadInfo = true
        }
      }, 2000)
    })
  },
  methods: {
    dismissShowAppDownload() {
      this.showAppDownloadInfo = false
      const now = new Date()
      const oneYear = new Date(now.getTime() + 365 * 24 * 60 * 60 * 1000)
      this.$cookies.set('show-app-download', true, { expires: oneYear })
    },
    downloadAppButtonGtm(type) {
      this.$gtm.push({
        event: type === 'google-play' ? 'download_on_google_play' : 'download_on_app_store',
      })
    },
  },
}
