export default {
    setLoading(state, data) {
      state.loading = data
    },
    setEvents(state, data) {
        state.events = data
    },
    setEvent(state, data) {
        state.event = data
    }
}
  