
import get from 'lodash/get'
import { mapActions, mapState } from 'vuex'
import once from 'lodash/once'
import { showError } from '~/utils'
import AgoraService from '~/utils/agora'

const LayoutFooter = () => import('~/components/Layouts/LayoutFooterNew.vue')
const LayoutHeader = () => import('~/components/Layouts/MainHeader.vue')
const ScrollTop = () => import('~/components/Button/ScrollTop.vue')
const ManageMenu = () => import('~/containers/Dashboard/SideMenu.vue')
const SignUpUserModal = () => import('~/components/Modal/SignUpUserModal/index.vue')
const PaymentLoading = () => import('~/components/PaymentLoading/index.vue')

export default {
  colorMode: 'dark',
  components: {
    LayoutFooter,
    LayoutHeader,
    ScrollTop,
    ManageMenu,
    SignUpUserModal,
    PaymentLoading,
  },
  data() {
    return {
      showPopup: false,
      mounted: false,
      attempt: 1,
      // list country default language base
      defaultLangCountry: ['TW', 'ID', 'TH', 'VN'],
    }
  },
  head() {
    return this.generateHeadTitle()
  },
  computed: {
    userId() {
      return get(this.$auth, 'user.id') || ''
    },
    userRoles() {
      return get(this.$auth, 'user.userType') || ''
    },
    ...mapState({
      countryUser: state => state.settings.countryUser,
      authStrategy: state => state.auth.strategy,
    }),
    isVerified() {
      return this.$auth.loggedIn && this.$auth.user.verified === true
    },
  },
  watch: {
    // Watch the route to update the body class when the route changes
    '$route.name': {
      immediate: true,
      handler(newVal) {
        this.updateBodyClass(newVal)
      },
    },
  },
  async mounted() {
    if (typeof window.Stripe === 'undefined') {
      await this.importStripe()
      this.attempt += 1
    }

    if (this.countryUser === '') {
      if (!this.$auth.loggedIn) {
        await this.getCountryUser()
        if (this.defaultLangCountry.includes(this.countryUser)) {
          const lang = this.countryUser === 'VN' ? 'vi' : this.countryUser.toLowerCase()
          this.$store.commit('settings/setLocale', lang)
          this.$dayjs.locale(lang)
          this.$i18n.setLocale(lang)
        }
      } else {
        this.$store.commit('settings/setCountryUser', get(this.$auth, 'user.profile.countryCode'))
      }
    }
    if (this.userId) {
      this.$gtag.config({ user_id: this.userId })
      this.$gtm.push({ event: 'userIdSet', user_id: this.userId, roles: this.userRoles })
      this.getTiers()
      this.getUserSummary(this.userId)
    }

    const isNewUser = this.$auth.$storage.getUniversal('isNewUser')
    if (this.$auth.loggedIn) {
      this.getClaimedDiscord()

      if (isNewUser) {
        this.showPopup = true
        this.$auth.$storage.removeUniversal('isNewUser')
      }
    }
    this.$nextTick().then(() => {
      this.mounted = true
    })

    if (this.$auth.loggedIn && this.$auth.user && this.$auth.user.verified) {
      once(() => {
        this.initializeChat()
        const agoraService = new AgoraService(this)
        agoraService.initialize()
      })()
    }
  },
  methods: {
    ...mapActions({
      getCountryUser: 'settings/getCountryUser',
      getTiers: 'settings/getTiers',
      getUserSummary: 'profile/getUserSummary',
      getClaimedDiscord: 'settings/getClaimedDiscord',
      initializeChat: 'chat/initializeChat',
    }),
    async verifyEmail() {
      try {
        await this.$axios.post('/users/send-verify', { email: this.$auth.user.email })
        this.$message.success(this.$t('verifyEmailNotice'))
        window.location.reload()
      } catch (error) {
        showError(error, '')
      }
    },
    importStripe() {
      // stop if already attempted 30 times
      if (this.attempt === 30) {
        const error = new Error('Stripe could not be loaded')
        this.$sentry.captureException(error)
        return
      }

      // every 10 attempts, reimport script
      if (this.attempt % 10 === 0) {
        const script = document.createElement('script')
        script.src = 'https://js.stripe.com/v3'
        script.hid = 'stripe_js'
        script.defer = true

        document.head.appendChild(script)
      }

      // if Stripe not loaded yet, re-execute this function
      if (typeof window.Stripe === 'undefined') {
        setTimeout(this.importStripe, 500)
        this.attempt += 1
      }
    },
    generateHeadTitle() {
      const pathTitleMap = {
        '/manage/notifications': 'Gank - Notifications',
        '/manage/my-orders': 'Gank - My Orders',
        '/manage/my-following': 'Gank - My Following',
        '/manage/my-subscriptions': 'Gank - My Subscriptions',
        '/manage/my-transactions': 'Gank - My Transactions',
        '/manage/my-supporters': 'Gank - My Supporters',
        '/manage/donation-settings': 'Gank - Donation Settings',
        '/manage/stream-settings': 'Gank - Stream Settings',
        '/manage/membership-settings': 'Gank - Membership Settings',
        '/manage/my-listings': 'Gank - My Listings',
        '/manage/get-stream-access': 'Gank - Beta Application',
        '/manage/referral': 'Gank - Referral',
        '/manage/account': 'Gank - My Account',
      }
      const { path } = this.$route

      // Check if the path exists in the map
      if (Object.keys(pathTitleMap).includes(path)) {
        const title = pathTitleMap[path]
        const canonicalHref = `https://ganknow.com${path}`

        return {
          title,
          link: [{ rel: 'canonical', href: canonicalHref }],
        }
      }
      return {}
    },
    updateBodyClass(routeName) {
      if (process.client) {
        // Ensure this runs only on the client side
        document.body.className = ''
        document.body.classList.add(`page-${routeName}`)
      }
    },
  },
}
