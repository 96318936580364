const middleware = {}

middleware['auth-custom'] = require('../middleware/auth-custom.js')
middleware['auth-custom'] = middleware['auth-custom'].default || middleware['auth-custom']

middleware['proxy'] = require('../middleware/proxy.js')
middleware['proxy'] = middleware['proxy'].default || middleware['proxy']

middleware['sentry'] = require('../middleware/sentry.js')
middleware['sentry'] = middleware['sentry'].default || middleware['sentry']

middleware['user-role'] = require('../middleware/user-role.js')
middleware['user-role'] = middleware['user-role'].default || middleware['user-role']

middleware['username-validation'] = require('../middleware/username-validation.js')
middleware['username-validation'] = middleware['username-validation'].default || middleware['username-validation']

export default middleware
