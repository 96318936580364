const getters = {
  getServices: (state) => {
    return state.services
  },
  getProfiles: (state) => {
    return state.profiles
  },
  getGames: (state) => {
    return state.games
  },
  getUnitListing: (state) => {
    return state.unitListing
  },
}

export default getters
