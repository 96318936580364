export default {
  setSidebarMenu(state, data) {
    state.sidebarMenu = data
  },
  setExpandedState(state, { index, expanded }) {
    if (state.sidebarMenu[index]) {
      state.sidebarMenu[index] = { ...state.sidebarMenu[index], expanded }
    }
  },
  toggleExpandedState(state, index) {
    if (state.sidebarMenu[index]) {
      state.sidebarMenu[index] = { ...state.sidebarMenu[index], expanded: !state.sidebarMenu[index].expanded }
    }
  },
  setCurrentContextType(state, type) {
    state.currentContextType = type
  },
}
