
import Paddie from '~/assets/images/paddie/paddie-404.png'

const PaymentLoading = () => import('~/components/PaymentLoading/index.vue')

export default {
  name: 'NuxtError',
  components: {
    PaymentLoading,
  },
  props: {
    error: {
      type: Object,
      default: null,
    },
  },
  head() {
    return {
      title: this.message,
      meta: [
        {
          name: 'viewport',
          content: 'width=device-width,initial-scale=1.0,minimum-scale=1.0',
        },
      ],
    }
  },
  computed: {
    statusCode() {
      return (this.error && this.error.statusCode) || 500
    },
    message() {
      return this.error.message || '<%= messages.client_error %>'
    },
    paddieLogo() {
      return Paddie
    },
  },
}
