import initialState from './state'

export default {
  resetState(state) {
    Object.assign(state, initialState())
  },
  setUser(state, data) {
    state.user = data
  },
  setUserGames(state, data) {
    state.userGames = data
  },
  setUserGameSpecs(state, data) {
    state.userGameSpecs = data
  },
  setMarketplaceGames(state, data) {
    state.marketplaceGames = data
  },
  setUserProfile(state, data) {
    state.userProfile = data
  },
  setUserAchievements(state, data) {
    state.userAchievements = data
  },
  setUserShowreels(state, data) {
    state.userShowreels = data
  },
  setUserFollowing(state, data) {
    state.userFollowing = data
  },
  setUserSubscriptions(state, data) {
    state.userSubscriptions = data
  },
  pushUserSubscriptions(state, data) {
    state.userSubscriptions = [...new Set([...state.userSubscriptions, data])]
  },
  setUserSubscriptionsObject(state, data) {
    state.userSubscriptionsObject = data
  },
  pushUserSubscriptionsObject(state, data) {
    state.userSubscriptionsObject = [...new Set([...state.userSubscriptionsObject, data])]
  },
  setUserSubscriptionsUserId(state, data) {
    state.userSubscriptionsUserId = data
  },
  setpaymentJustSucceed(state, data) {
    state.paymentJustSucceed = data
  },
  pushUserSubscriptionsUserId(state, data) {
    state.userSubscriptionsUserId = [...new Set([...state.userSubscriptionsUserId, data])]
  },
  pushUserFollowing(state, data) {
    state.userFollowing = [...new Set([...state.userFollowing, data])]
  },
  removeUserFollowing(state, userID) {
    const idx = state.userFollowing.findIndex(v => v === userID)
    if (idx > -1) {
      const clone = JSON.parse(JSON.stringify(state.userFollowing))
      clone.splice(idx, 1)
      state.userFollowing = clone
    }
  },
  setUserGoal(state, data) {
    state.userGoal = data
  },
  setUserSummary(state, data) {
    state.userSummary = data
  },
  setUserTier(state, data) {
    state.userTier = data
  },
  setUserCompletedGoals(state, data) {
    state.userCompletedGoals = data
  },
  setUserProgressionTask(state, data) {
    state.userProgressionTask = data
  },
  ADD_TO_FOLLOWING(state, userId) {
    if (!state.userFollowing.includes(userId)) {
      state.userFollowing.push(userId)
    }
  },
  REMOVE_FROM_FOLLOWING(state, userId) {
    const index = state.userFollowing.indexOf(userId)
    if (index > -1) {
      state.userFollowing.splice(index, 1)
    }
  },
  setProgressionTaskHighlight(state, type) {
    const highlightProperties = [
      'highlightEnableDonation',
      'highlightEnableMembership',
      'highlightCreatePost',
      'highlightShopCard',
      'highlightStreamAlert',
    ]

    highlightProperties.forEach(key => {
      state[key] = (key === type)
    })
  },
  resetProgressionTaskHighlight(state) {
    state.highlightEnableDonation = false
    state.highlightEnableMembership = false
    state.highlightCreatePost = false
    state.highlightShopCard = false
    state.highlightStreamAlert = false
  },
  setIsJustEnabledDonations(state, value) {
    state.isJustEnabledDonations = value
  },
  setUserDiscountPPV(state, data) {
    state.userDiscountPPV = data
  },
  setUserDiscountShop(state, data) {
    state.userDiscountShop = data
  },
  setUserDiscountMembership(state, data) {
    state.userDiscountMembership = data
  },
  setFetchGoalLoading(state, value) {
    state.fetchGoalLoading = value
  },
}
