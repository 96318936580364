import get from 'lodash/get'
import stringify from 'qs/lib/stringify'
import { showError } from '~/utils'

export default {
  // unused
  async getCMS({ commit }, filters) {
    commit('setLoading', true)
    try {
      const res = await this.$axios.get(`/settings/cms?${stringify(filters, { encode: false })}`)
      commit('setAllCMS', get(res, 'data.data') || [])
    } catch (error) {
      showError(error, 'Get CMS failed!')
    } finally {
      commit('setLoading', false)
    }
  },
  // unused
  async getPageBySlug({ commit }, slug) {
    commit('setLoading', true)
    try {
      const res = await this.$axios.get(`settings/cms/slug/${slug}`)
      commit('setCMS', get(res, 'data.data') || {})
    } catch (error) {
      showError(error, 'Get content failed!')
      window.$nuxt.error({
        statusCode: 404,
        message: 'This page could not be found',
      })
    } finally {
      commit('setLoading', false)
    }
  },
}
