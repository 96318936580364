export default () => ({
  currentCountryCode: '',
  allCountries: [],
  languages: [],
  loadingHighlightBanner: false,
  loadingFeaturedBanner: false,
  loadingGamerSpotlight: false,
  loadingMediaShowreel: false,
  loadingSections: false,
  featuredBanners: [],
  highlightBanners: [],
  gamerSpotlight: [],
  mediaShowreels: [],
  sections: [],
  countryUser: '',
  locale: '',
  tiers: [],
  membershipSelected: null,
  claimDiscord: null,
  interest: [],
  existingStreamUser: null,
})
