export default {
  setPlayingMedia(state, data) {
    state.playingMedia = data
  },
  setQueueMedia(state, data) {
    console.log('setQueueMedia', data)
    state.queueMedia = data
  },
  setPreviousMedia(state, data) {
    console.log('setPreviousMedia', data)
    state.previousMedia = data
  },
  pushMultiplePreviousMedia(state, data) {
    console.log('pushMultiplePreviousMedia', data)
    state.previousMedia.push(...data)
  },
  setPendingMedia(state, data) {
    console.log('setPendingMedia', data)
    state.pendingMedia = data
  },
  pushQueueMedia(state, data) {
    console.log('pushQueueMedia', data)
    state.queueMedia.push(data)
  },
  popQueueMedia(state) {
    console.log('popQueueMedia')
    state.queueMedia.splice(0, 1)
  },
  removeQueueMedia(state, data) {
    console.log('removeQueueMedia', data)

    console.log('removeQueueMedia queueMedia 1', state.queueMedia, state.queueMedia.length)
    const index = state.queueMedia.findIndex(item => item.queue.id === data.id)
    console.log('removeQueueMedia index', index)

    if (index !== -1) {
      state.queueMedia.splice(index, 1)
      console.log('removeQueueMedia queueMedia 2', state.queueMedia)
    }
  },
  removePendingMedia(state, data) {
    const index = state.pendingMedia.findIndex(item => item.id === data.id)

    if (index !== -1) {
      state.pendingMedia.splice(index, 1)
    }
  },
  removePreviousMedia(state, data) {
    const index = state.previousMedia.findIndex(item => item.id === data.id)

    if (index !== -1) {
      state.previousMedia.splice(index, 1)
    }
  },
  setFirstQueue(state, data) {
    console.log('setFirstQueue', data)
    state.firstQueue = data
  },
  setSocket(state, data) {
    console.log('setSocket', data)
    state.socketMediaShare = data
  },
  pushPreviousMedia(state, data) {
    console.log('pushPreviousMedia', data)
    state.previousMedia.splice(0, 0, data)
  },
}
