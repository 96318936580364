import Vue from 'vue'
import VuePlyr from '@do4mother/vue-plyr/dist/vue-plyr.ssr'
import '@do4mother/vue-plyr/dist/vue-plyr.css'

// The second argument is optional and sets the default config values for every player.
Vue.use(VuePlyr, {
  plyr: {
    fullscreen: { enabled: false },
  },
  emit: ['ended'],
})
